import { Component } from '@angular/core';
import { GoogleAnalyticsService } from './../../../core/services';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  constructor(private _googleAnalyticsService: GoogleAnalyticsService) {}

  sendClickEvent(name: string): void {
    this._googleAnalyticsService.eventEmitter(
      `${name}_click`,
      'landing',
      'click'
    );
  }
}
