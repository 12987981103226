import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState,
} from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  appLayout;
  screenSize: string;
  screenSizes: string[] = ['XSmall', 'Small', 'Medium', 'Large', 'XLarge'];
  constructor(public breakpointObserver: BreakpointObserver) {}

  getScreenSize(): Observable<string> {
    this.detectSize();
    return of(this.screenSize);
  }

  detectSize(): void {
    this.screenSizes.forEach((breakpoint: string) => {
      this.breakpointObserver
        .observe(Breakpoints[breakpoint])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            this.screenSize = breakpoint;
          }
        });
    });
  }

  // detectOrientation(): void {
  //   this.breakpointObserver
  //     .observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Web])
  //     .subscribe((state: BreakpointState) => {
  //       console.log(state);
  //       console.log(state.breakpoints);
  //     });
  // }
}
