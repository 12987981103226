import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ApiService, GoogleAnalyticsService } from 'src/app/core/services';
import { IBlogPayload, IBlogPost } from 'src/app/models';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss'],
})
export class BlogComponent implements OnInit {
  blogSubscription: Subscription;
  blogPosts: IBlogPost[];
  isLoaded: boolean;
  moreLabel = 'More on Medium ->';
  titleLabel = 'Notes and Thoughts';

  constructor(
    private _apiService: ApiService,
    private _googleAnalyticsService: GoogleAnalyticsService
  ) {}

  ngOnInit(): void {
    this.blogSubscription = this._apiService
      .getBlogPosts()
      .subscribe((payload: IBlogPayload) => {
        if (payload.status === 'ok') {
          this.isLoaded = true;
          this.blogPosts = payload.items.slice(0, 5).map((post: IBlogPost) => {
            return {
              title: post.title,
              pubDate: post.pubDate.split(' ')[0],
              link: post.link,
              thumbnail: post.thumbnail,
              description: this._transformDescription(post.description),
            };
          });
        } else {
          this.isLoaded = false;
        }
      });
  }

  ngOnDestroy(): void {
    this.blogSubscription.unsubscribe();
  }

  sendClickEvent(name: string): void {
    this._googleAnalyticsService.eventEmitter(
      `${name}_click`,
      'landing',
      'click'
    );
  }

  private _transformDescription(node): string {
    const text: string = this._toText(node);
    return this._shortenText(text, 0, 450);
  }

  private _toText(node): string {
    let tag = document.createElement('div');
    tag.innerHTML = node;
    node = tag.innerText;
    return node;
  }

  private _shortenText(text, startingPoint, maxLength): string {
    let shortText =
      text.length > maxLength ? text.slice(startingPoint, maxLength) : text;
    return (shortText += '...');
  }
}
