import { IBlogPayload } from '../models';

export const MEDIUM_PAYLOAD: IBlogPayload = {
  status: 'ok',
  items: [
    {
      title: 'How to Automate Web Accessibility Testing',
      pubDate: '2019-06-11',
      link:
        'https://medium.com/@f3igao/how-to-automate-web-accessibility-testing-921512bdd4bf?source=rss-88a580254f61------2',
      thumbnail:
        'https://cdn-images-1.medium.com/max/650/1*YkbelMNi8bxQIYu4HCVEKw.png',
      description:
        '\nAnd make it part of your continuous integration pipeline\nAs outlined in my previous post, making web sites and apps available to everyone is an essential part of design and development. However, enforcing digital accessibility can quickly become a difficult and overwhelming task. It takes time and commitment to understand and adopt the Web Content Accessibility Guidelines (WCAG), and the barrier becomes even greater when working on team projects...',
    },
    {
      title: 'An Internet for Everybody',
      pubDate: '2019-05-29',
      link:
        'https://medium.com/@f3igao/an-internet-for-everybody-2f8fa48b87b0?source=rss-88a580254f61------2',
      thumbnail:
        'https://cdn-images-1.medium.com/max/599/0*cXk0EyGDqCJjWJcv.png',
      description:
        '\nWhat is web accessibility and why you should care\nLast year, the United States saw over 2,000 federal lawsuits over web accessibility, a 181% jump from 2017. Ensuring accessibility is an emerging trend in the digital world, but what exactly is web accessibility and why is it important?\nSource: UsableNetWeb accessibility, also known as a11y, is the inclusive practice of making sites and apps available to everyone. As the internet becomes an incre...',
    },
    {
      title: 'Get Started with Git Hooks',
      pubDate: '2018-11-25',
      link:
        'https://medium.com/@f3igao/get-started-with-git-hooks-5a489725c639?source=rss-88a580254f61------2',
      thumbnail:
        'https://cdn-images-1.medium.com/max/1024/1*B1MvSe4duhojijKURp-DJw.jpeg',
      description:
        '\nEnforce code quality and increase productivity\nGit, one of the most popular version-control systems (VCS) for modern software development, is used to coordinate changes for source code files and facilitate teamwork among multiple developers. Using a “hooks system,” Git allows developers and administrators to perform custom actions at every stage of the version-control process (e.g. before a commit, after a commit, before a push, etc.)\nI came acr...',
    },
    {
      title: 'Using Rails Action Cable to Set Up a Chat App',
      pubDate: '2018-04-02',
      link:
        'https://medium.com/@f3igao/set-up-a-chat-app-backend-with-rails-action-cable-d60f0a6e63c1?source=rss-88a580254f61------2',
      thumbnail:
        'https://cdn-images-1.medium.com/max/1024/1*MvZnWmQuKexgSrST-ZAv_Q.png',
      description:
        '\nThe Rails open source community released Rails 5.0 in June 2016. Along with many exciting features, this version introduced Action Cable.\n\nAction Cable seamlessly integrates WebSockets with the rest of your Rails application. It allows for real-time features to be written in Ruby in the same style and form as the rest of your Rails application, while still being performant and scalable. It’s a full-stack offering that provides both a client-side...',
    },
    {
      title: 'Digital Easter Eggs',
      pubDate: '2018-03-12',
      link:
        'https://medium.com/@f3igao/digital-easter-eggs-b9837b9c0603?source=rss-88a580254f61------2',
      thumbnail:
        'https://cdn-images-1.medium.com/max/633/1*gNobWiykWmspPWx_bfuJUA.gif',
      description:
        '\nSecret messages and features in our virtual world\nAs I began to think more about user interface (UI) design, I learned that certain programmers use what is commonly referred to as “digital easter eggs” to enhance user experience (UX).\nDigital easter eggs — also known as “computer easter eggs,” “virtual easter eggs,” and “media easter eggs” — are obscure features or messages buried in media products. They are not immediately obvious to users and ...',
    },
  ],
};
