import { Component } from '@angular/core';
import { GoogleAnalyticsService } from './../../../core/services';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
})
export class ContactComponent {
  contactText: string =
    "Interested in working together? Looking to build a new project or improving an existing one? Let's connect.";
  titleLabel = 'Say Hi';

  constructor(private _googleAnalyticsService: GoogleAnalyticsService) {}

  sendClickEvent(name: string): void {
    this._googleAnalyticsService.eventEmitter(
      `${name}_click`,
      'contact',
      'click'
    );
  }
}
