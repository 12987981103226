import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BlogPostModule } from '../../components';
import { BlogComponent } from './blog.component';

const DECLARATIONS = [BlogComponent];

const MODULES = [CommonModule, BlogPostModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class BlogModule {}
