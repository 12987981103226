import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { ProjectCardComponent } from './project-card.component';

const DECLARATIONS = [ProjectCardComponent];

const MODULES = [CommonModule, MatCardModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class ProjectCardModule {}
