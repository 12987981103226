import { MatIconModule } from '@angular/material/icon';
import { StrengthCardComponent } from './strength-card.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

const DECLARATIONS = [StrengthCardComponent];

const MODULES = [CommonModule, MatIconModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class StrengthCardModule {}
