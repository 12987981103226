import { Component } from '@angular/core';
import { INavOption } from 'src/app/models';
import { NAV_OPTIONS } from '../../../mocks';
import { GoogleAnalyticsService } from '../../services';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  navOptions: INavOption[] = NAV_OPTIONS.slice(1);

  constructor(private _googleAnalyticsService: GoogleAnalyticsService) {}

  sendClickEvent(name: string): void {
    this._googleAnalyticsService.eventEmitter(
      `${name}_click`,
      'landing',
      'click'
    );
  }
}
