import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { GoogleAnalyticsService } from './core/services';
import { FeatureModule } from './feature/feature.module';

const MODULES = [
  BrowserModule,
  BrowserAnimationsModule,
  FeatureModule,
  CoreModule,
];

@NgModule({
  declarations: [AppComponent],
  imports: [...MODULES],
  providers: [GoogleAnalyticsService],
  bootstrap: [AppComponent],
})
export class AppModule {}
