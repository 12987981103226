import { ISkill } from './../models';

export const MOCK_SKILLS: ISkill[] = [
  {
    category: 'UX/UI',
    skills: [
      'Javascript, Typescript',
      'HTML, CSS, Sass',
      'Angular, React, Redux',
      'Dart, Flutter',
      'Invision Studio, Sketch',
    ],
  },
  {
    category: 'Backend/Server',
    skills: ['Python, Flask', 'Ruby, Rails', 'AWS, Heroku', 'IIS Web Server'],
  },
  {
    category: 'Database',
    skills: ['Firebase', 'MongoDB', 'SQL, PostgreSQL, MySQL'],
  },
  {
    category: 'Platforms',
    skills: ['Git, Github, BitBucket', 'Google Analytics', 'Salesforce'],
  },
];
