<div class="h-100 flex flex-column justify-center items-center">
    <p class="mt5-ns mb0-ns f3 b">{{ titleLabel }}</p>

    <div class="flex items-left fw5 w-80">
        <mat-icon class="ruby pr3">{{ webLabel.icon }}</mat-icon>
        <span>{{ webLabel.text | uppercase}}</span>
    </div>
    <cdk-virtual-scroll-viewport orientation="horizontal" [itemSize]="webProjects.length"
        class="virtual-scroll-viewport w-90 overflow-y-hidden">
        <div *cdkVirtualFor="let project of webProjects" class="mh3">
            <app-project-card [project]="project"></app-project-card>
        </div>
    </cdk-virtual-scroll-viewport>


    <div class="flex items-left fw5 w-80">
        <mat-icon class="ruby pr3">{{ mobileLabel.icon }}</mat-icon>
        <span>{{ mobileLabel.text | uppercase}}</span>
    </div>
    <cdk-virtual-scroll-viewport orientation="horizontal" [itemSize]="mobileProjects.length"
        class="virtual-scroll-viewport w-90 overflow-y-hidden">
        <div *cdkVirtualFor="let project of mobileProjects" class="mh3">
            <app-project-card [project]="project"></app-project-card>
        </div>
    </cdk-virtual-scroll-viewport>
</div>