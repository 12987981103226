import { Component, Input } from '@angular/core';
import { IProject } from 'src/app/models';

@Component({
  selector: 'app-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
})
export class ProjectCardComponent {
  @Input() project: IProject;

  onClick(event = null): void {
    if (event) {
      event.preventDefault();
    }
    if (this.project.isProductionReady) {
      window.open(this.project.url);
    }
  }
}
