import {
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { MOCK_MOBILE_PROJECTS, MOCK_WEB_PROJECTS } from 'src/app/mocks';
import { IProject, IProjectLabel } from 'src/app/models';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectsComponent {
  webLabel: IProjectLabel = {
    text: 'web',
    icon: 'laptop',
  };
  mobileLabel: IProjectLabel = {
    text: 'mobile',
    icon: 'smartphone',
  };
  webProjects: IProject[] = MOCK_WEB_PROJECTS;
  mobileProjects: IProject[] = MOCK_MOBILE_PROJECTS;
  titleLabel = 'Personal Projects';
}
