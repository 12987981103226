<nav class="fixed top-0">
    <ng-container *ngIf="isHandset; else isBrowser">
        <app-nav-side></app-nav-side>
    </ng-container>
    <ng-template #isBrowser>
        <app-nav-bar></app-nav-bar>
    </ng-template>
</nav>

<main class="h-100" [@routeAnimations]="prepareRoute(outlet)">
    <router-outlet #outlet="outlet"></router-outlet>
</main>