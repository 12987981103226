import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { IBlogPayload } from 'src/app/models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _httpService: HttpService) {}

  getBlogPosts(): Observable<IBlogPayload> {
    const url =
      'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@f3igao';
    return this._httpService.get(url);
  }
}
