import { MOCK_STRENGTHS, MOCK_SKILLS } from './../../../mocks';
import { IStrength, ISkill } from './../../../models';
import { Component } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
})
export class AboutComponent {
  strengths: IStrength[] = MOCK_STRENGTHS;
  skills: ISkill[] = MOCK_SKILLS;
  titleLabel: string = 'Who am I?';
}
