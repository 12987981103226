<div class="flex flex-column">
    <div class="flex flex-column flex-row-ns justify-between items-center-ns">
        <a [href]="post?.link" target="_blank" class="flex items-center no-underline" (click)="sendClickEvent('title_' + post.title)">
            <mat-icon class="ruby pr3">notes</mat-icon>
            <span class="ruby no-underline underline-hover ponter fw5">{{ post?.title | uppercase }}</span>
        </a>
        <span class="tr taupe-grey">{{ post?.pubDate }}</span>
    </div>
    <p class="tj lh-title">{{ post?.description }}
        <a [href]="post?.link" target="_blank" class="taupe-grey" (click)="sendClickEvent('more_' + post.title)">Continue on Medium</a>
    </p>
</div>