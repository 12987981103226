import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { LandingComponent } from './landing.component';

const DECLARATIONS = [LandingComponent];

const MODULES = [
  CommonModule,
  MatIconModule,
  AppRoutingModule,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class LandingModule {}
