import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from './feature/containers/about/about.component';
import { BlogComponent } from './feature/containers/blog/blog.component';
import { ContactComponent } from './feature/containers/contact/contact.component';
import { LandingComponent } from './feature/containers/landing/landing.component';
import { ProjectsComponent } from './feature/containers/projects/projects.component';

const routes: Routes = [
  { path: '', component: LandingComponent, data: { animation: 'HomePage' } },
  { path: 'home', redirectTo: '', pathMatch: 'full' },
  {
    path: 'about',
    component: AboutComponent,
    pathMatch: 'full',
    data: { animation: 'AboutPage' },
  },
  {
    path: 'portfolio',
    component: ProjectsComponent,
    pathMatch: 'full',
    data: { animation: 'PortfolioPage' },
  },
  {
    path: 'blog',
    component: BlogComponent,
    pathMatch: 'full',
    data: { animation: 'BlogPage' },
  },
  {
    path: 'contact',
    component: ContactComponent,
    pathMatch: 'full',
    data: { animation: 'ContactPage' },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
