import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NavBarModule, NavSideModule } from "./components";
import { ScrollSpyDirective } from "./directives/scroll-spy/scroll-spy.directive";

const DECLARATIONS = [ScrollSpyDirective];

const MODULES = [CommonModule, NavBarModule, NavSideModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES]
})
export class CoreModule {}
