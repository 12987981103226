import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AboutComponent } from './about.component';
import {
  BioCardModule,
  StrengthCardModule,
  SkillCardModule,
} from '../../components';

const DECLARATIONS = [AboutComponent];

const MODULES = [
  CommonModule,
  BioCardModule,
  StrengthCardModule,
  SkillCardModule,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class AboutModule {}
