import { IStrength } from './../models';

export const MOCK_STRENGTHS: IStrength[] = [
  {
    title: 'fast',
    description:
      'Quick load times and lag-free interaction allows for a smooth and seamless user experience',
    icon: 'timelapse',
  },
  {
    title: 'intuitive',
    description:
      'Easy-to-use and easy-to-understand user interface enables maximum efficiency',
    icon: 'person_pin',
  },
  {
    title: 'responsive',
    description:
      'Cross-browser and cross-device compatibility ensures widest possible user base',
    icon: 'devices_other',
  },
  {
    title: 'sleek',
    description:
      'Elegant solutions built with modern frameworks and great attention to details',
    icon: 'all_inclusive',
  },
];
