import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ProjectCardModule } from './../../components';
import { ProjectsComponent } from './projects.component';

const DECLARATIONS = [ProjectsComponent];

const MODULES = [
  CommonModule,
  ScrollingModule,
  ProjectCardModule,
  MatIconModule,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class ProjectsModule {}
