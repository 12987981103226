import { Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NAV_OPTIONS } from 'src/app/mocks';
import { INavOption } from 'src/app/models';

@Component({
  selector: 'app-nav-side',
  templateUrl: './nav-side.component.html',
  styleUrls: ['./nav-side.component.scss'],
})
export class NavSideComponent {
  @ViewChild('sidenav') sidenav: MatSidenav;
  showSidenav = false;
  navOptions: INavOption[] = NAV_OPTIONS;
}
