<div class="h-100 mh3 flex flex-column justify-center lh-copy">
  <p class="f2 f1-ns tc mv0">
    Hello, my name is ​<span class="fw6 ruby">Fei Gao​</span>.
  </p>
  <p class="f4 f2-ns tc mv4 mh4-m mh6-l">
    I’m a software engineer with a passion for human-focused technology.
  </p>

  <div class="center mt4">
    <a routerLink="/about" aria-label="about" (click)="sendClickEvent('right_arrow_icon')">
      <mat-icon class="icon-grow-lg taupe-grey ruby-hover pointer">
        arrow_right_alt
      </mat-icon>
    </a>
  </div>
</div>