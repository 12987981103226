<div class="flex flex-column justify-center justify-around-l items-center ph4 pt4 pt5-ns lh-copy">
  <p class="f3 b mv4-ns">{{ titleLabel }}</p>
  <div class="flex justify-center items-center">
    <div class="w-100 w-40-ns">
      <app-bio-card></app-bio-card>
    </div>

    <div class="dn db-ns w-40 mh4">
      <div class="flex flex-wrap justify-around">
        <div class="w-40 mb3" *ngFor="let strength of strengths">
          <app-strength-card [strength]="strength"></app-strength-card>
        </div>
      </div>
    </div>
  </div>