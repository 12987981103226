import { INavOption } from '../models';

export const NAV_OPTIONS: INavOption[] = [
  {
    display: 'home',
    route: '/',
  },
  {
    display: 'about',
    route: '/about',
  },
  {
    display: 'portfolio',
    route: '/portfolio',
  },
  {
    display: 'blog',
    route: '/blog',
  },
  {
    display: 'contact',
    route: '/contact',
  },
];
