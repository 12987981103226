import { IProject } from 'src/app/models';

export const MOCK_WEB_PROJECTS: IProject[] = [
  {
    name: 'nuts n bolts',
    imageFile: 'project_nuts-n-bolts.png',
    isProductionReady: true,
    isMobile: false,
    description:
      'Web accessibility (a11y) project to try out and demo the functionalities of Pa11y CI, a tool to automate a11y testing and make it part of the CI/CD pipeline.',
    builtWith: 'Angular, Typescript, SCSS, Tachyons, Angular Material, Pa11y',
    url: 'https://f3igao.github.io/nuts-n-bolts/',
  },
  {
    name: 'wandr',
    imageFile: 'project_wandr.png',
    isProductionReady: true,
    isMobile: false,
    description:
      'A social media platform for travelers to plan their trips and share their itineraries with friends.',
    builtWith:
      'ReactJS, Javascript, Semantic UI, CSS, Ruby on Rails, PostgreSQL, Google Maps API, Heroku',
    url: 'https://wandr-frontend.herokuapp.com',
  },
  {
    name: 'flippa gif',
    imageFile: 'project_flippa-gif.png',
    isProductionReady: true,
    isMobile: false,
    description:
      'A digital rendition of the age-old card game known as Concentration. (Recommended Browser: Chrome)',
    builtWith:
      ' Vanilla Javascript, CSS Animations, Ruby on Rails, PostgreSQL, Heroku',
    url: 'https://f3igao.github.io/flippa-gif/',
  },
  {
    name: 'safespace',
    imageFile: 'project_safespace.png',
    isProductionReady: true,
    isMobile: false,
    description:
      'An anonymous social network platform for women worldwide to share their experiences, ask questions, and build community.',
    builtWith:
      'ReactJS, Javascript, Semantic UI, CSS, Ruby on Rails, PostgreSQL, Google Maps API, Heroku',
    url: 'https://safespace-frontend.herokuapp.com/',
  },
];

export const MOCK_MOBILE_PROJECTS: IProject[] = [
  {
    name: 'namaslay',
    imageFile: 'project_namaslay.png',
    isProductionReady: true,
    isMobile: true,
    description: 'Yoga app for busy people',
    builtWith: 'Flutter, Dart, Firebase',
    url: 'https://play.google.com/store/apps/details?id=xyz.namaslay.flutter',
  },
  {
    name: 'melo',
    imageFile: 'project_melo.png',
    isProductionReady: false,
    isMobile: true,
    description: 'Social media platform to share the melodies in your life',
    builtWith: 'Flutter, Dart, Firebase',
    url: 'github.com',
  },
];
