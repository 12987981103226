import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const slideInAnimation = trigger('routeAnimations', [
  transition('HomePage => AboutPage', [
    style({ position: 'relative' }),
    query(':enter, :leave', [
      style({
        position: 'absolute',
        height: '100%',
        width: '100%',
      }),
    ]),
    query(':enter', [style({ right: '-100%' })]),
    query(':leave', animateChild()),
    group([
      query(':leave', [animate('0ms ease-out', style({ right: '100%' }))]),
      query(':enter', [animate('250ms ease-out', style({ right: '0%' }))]),
    ]),
    query(':enter', animateChild()),
  ]),
  // transition('* => HomePage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       height: '100%',
  //       width: '100%',
  //     }),
  //   ]),
  //   query(':enter', [style({ opacity: '0%' })]),
  //   query(':leave', animateChild(), { optional: true }),
  //   group([
  //     query(':leave', [animate('0ms ease-out', style({ opacity: '0%' }))], {
  //       optional: true,
  //     }),
  //     query(':enter', [animate('500ms ease-out', style({ opacity: '100%' }))]),
  //   ]),
  //   query(':enter', animateChild()),
  // ]),
  // transition('AboutPage <=> PortfolioPage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       height: '100%',
  //       width: '100%',
  //     }),
  //   ]),
  //   query(':enter', [style({ opacity: '0%' })]),
  //   query(':leave', animateChild()),
  //   group([
  //     query(':leave', [animate('0ms ease-out', style({ opacity: '0%' }))]),
  //     query(':enter', [animate('500ms ease-out', style({ opacity: '100%' }))]),
  //   ]),
  //   query(':enter', animateChild()),
  // ]),
  // transition('AboutPage <=> BlogPage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       height: '100%',
  //       width: '100%',
  //     }),
  //   ]),
  //   query(':enter', [style({ opacity: '0%' })]),
  //   query(':leave', animateChild()),
  //   group([
  //     query(':leave', [animate('0ms ease-out', style({ opacity: '0%' }))]),
  //     query(':enter', [animate('500ms ease-out', style({ opacity: '100%' }))]),
  //   ]),
  //   query(':enter', animateChild()),
  // ]),
  // transition('AboutPage <=> ContactPage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       height: '100%',
  //       width: '100%',
  //     }),
  //   ]),
  //   query(':enter', [style({ opacity: '0%' })]),
  //   query(':leave', animateChild()),
  //   group([
  //     query(':leave', [animate('0ms ease-out', style({ opacity: '0%' }))]),
  //     query(':enter', [animate('500ms ease-out', style({ opacity: '100%' }))]),
  //   ]),
  //   query(':enter', animateChild()),
  // ]),
  // transition('PortfolioPage <=> BlogPage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       height: '100%',
  //       width: '100%',
  //     }),
  //   ]),
  //   query(':enter', [style({ opacity: '0%' })]),
  //   query(':leave', animateChild()),
  //   group([
  //     query(':leave', [animate('0ms ease-out', style({ opacity: '0%' }))]),
  //     query(':enter', [animate('500ms ease-out', style({ opacity: '100%' }))]),
  //   ]),
  //   query(':enter', animateChild()),
  // ]),
  // transition('PortfolioPage <=> ContactPage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       height: '100%',
  //       width: '100%',
  //     }),
  //   ]),
  //   query(':enter', [style({ opacity: '0%' })]),
  //   query(':leave', animateChild()),
  //   group([
  //     query(':leave', [animate('0ms ease-out', style({ opacity: '0%' }))]),
  //     query(':enter', [animate('500ms ease-out', style({ opacity: '100%' }))]),
  //   ]),
  //   query(':enter', animateChild()),
  // ]),
  // transition('BlogPage <=> ContactPage', [
  //   style({ position: 'relative' }),
  //   query(':enter, :leave', [
  //     style({
  //       position: 'absolute',
  //       height: '100%',
  //       width: '100%',
  //     }),
  //   ]),
  //   query(':enter', [style({ opacity: '0%' })]),
  //   query(':leave', animateChild()),
  //   group([
  //     query(':leave', [animate('0ms ease-out', style({ opacity: '0%' }))]),
  //     query(':enter', [animate('500ms ease-out', style({ opacity: '100%' }))]),
  //   ]),
  //   query(':enter', animateChild()),
  // ]),
]);
