import { Component } from '@angular/core';

@Component({
  selector: 'app-bio-card',
  templateUrl: './bio-card.component.html',
  styleUrls: ['./bio-card.component.scss'],
})
export class BioCardComponent {
  imagePath: string = 'assets/images/profile.JPG';
  bioBody: string =
    "I'm a web and mobile developer living in New York, working everywhere. With expertise on performance optimization and accessibility, I build solutions that empower people and make their lives easier.";
  contact: string = "Let's make something great together.";
}
