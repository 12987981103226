import { Component, Input } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/core/services';
import { IBlogPost } from 'src/app/models';

@Component({
  selector: 'app-blog-post',
  templateUrl: './blog-post.component.html',
  styleUrls: ['./blog-post.component.scss'],
})
export class BlogPostComponent {
  @Input() post: IBlogPost;

  constructor(private _googleAnalyticsService: GoogleAnalyticsService) {}

  sendClickEvent(name: string): void {
    this._googleAnalyticsService.eventEmitter(
      `${name}_${this.post.title}_click`,
      'landing',
      'click'
    );
  }
}
