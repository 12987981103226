<mat-toolbar class="fixed bg-off-white-translucent z-999">
    <mat-toolbar-row class="flex justify-between">
        <a routerLink="/" mat-menu-item class="mh2 ph3 grow bg-taupe-grey off-white br3 no-underline"
            (click)="sendClickEvent('f3i_logo')">f3i</a>
        <ol class="w-50-m w-third-l flex justify-around list">
            <li *ngFor="let option of navOptions" class="grow">
                <a [routerLink]="option.route" routerLinkActive="active" mat-menu-item
                    class="taupe-grey no-underline pa2"
                    (click)="sendClickEvent(option.display)">{{ option.display }}</a>
            </li>
        </ol>
    </mat-toolbar-row>
</mat-toolbar>