import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { AboutModule, BlogModule, LandingModule, ProjectsModule } from './containers';
import { ContactModule } from './containers/contact/contact.module';

const DECLARATIONS = [];

const MODULES = [
  CommonModule,
  HttpClientModule,
  LandingModule,
  AboutModule,
  BlogModule,
  ProjectsModule,
  ContactModule,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class FeatureModule {}
