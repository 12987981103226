import { Component, Input } from '@angular/core';
import { IStrength } from './../../../models';

@Component({
  selector: 'app-strength-card',
  templateUrl: './strength-card.component.html',
  styleUrls: ['./strength-card.component.scss'],
})
export class StrengthCardComponent {
  @Input() strength: IStrength;
}
