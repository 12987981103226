import { AppRoutingModule } from 'src/app/app-routing.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NavBarComponent } from './nav-bar.component';

const DECLARATIONS = [NavBarComponent];

const MODULES = [CommonModule, MatToolbarModule, AppRoutingModule];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [...MODULES],
  exports: [...DECLARATIONS, ...MODULES],
})
export class NavBarModule {}
