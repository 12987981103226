<div [style.background-image]="'url(/assets/images/' + project?.imageFile + ')'" tabindex="0"
    class="project-card center hide-child br2 cover bg-center shadow-2"
    [ngClass]="{'pointer': project?.isProductionReady, 'is-mobile': project?.isMobile}" (click)="onClick()"
    (keydown.enter)="onClick()" (keydown.space)="onClick($event)">
    <div class="white dtc h-100 child bg-black-70 flex flex-column justify-center tc ph4">
        <ng-container *ngIf="project?.isProductionReady; else notReady">
            <span class="b f4">{{ project?.name }}</span>
            <span class="mv2 f5">{{ project?.description}}</span>
            <span class="mt4-ns f7">{{ project?.builtWith }}</span>
        </ng-container>
        <ng-template #notReady>
            <span class="b f4">{{ project?.name }}</span>
            <span class="mv2">Coming Soon</span>
        </ng-template>
    </div>
</div>